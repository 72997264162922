import React, { useEffect, useRef } from 'react';
import { IAreaForm } from '../../app/interfaces';
import { Text } from '../typography';
import { Card } from '../card';
import { CloseIcon } from '../svg';
import { Badge } from '../badge';

const AreaForm = ({ formData, setFormData, setFormVisible, areaIndex }: IAreaForm) => {
    // create a mutable ref to store the areaData
    const areaDataRef = useRef<any>(null);
    // add an event listener to detect clicks outside the popup
    useEffect(() => {
        // update the ref whenever the areaData changes
        areaDataRef.current = formData.find(area => area.id === areaIndex);
        const handleOutsideClick: any = (event: MouseEvent) => {
            const targetElement = event.target as Element;
            if (targetElement && !targetElement.closest('.area-detail-block')) {
                handleOnClose();
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [formData, areaIndex]);

    // function to handle form field changes
    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { name, value } = e.target;

        // validate maximum slope value
        if (name === 'max_slope' && (value !== '' && (isNaN(Number(value)) || Number(value) < 0 || Number(value) > 360))) {
            return;
        }
        // validate available hours value
        if (name === 'available_hours' && (value !== '' && (isNaN(Number(value)) || Number(value) < 0 || Number(value) > 24))) {
            return;
        }
        const updatedFormData = formData.map(item => {
            if (item.id === areaIndex) {
                // return a new object with the updated area details	
                return { ...item, [name]: value };
            }
            return item;
        });
        setFormData(updatedFormData);
    };

    // function to handle grass quality changes
    const handleGrassQChange = (val: 1 | 2 | 3) => {
        const updatedFormData = formData.map(item => {
            if (item.id === areaIndex) {
                // Return a new object with the updated name
                return { ...item, cuttingQuality: val };
            }
            return item;
        });
        // update the state with the updated area form array
        setFormData(updatedFormData);
    };

    // function to handle form submission
    const handleOnClose = () => {
        setFormVisible(false);
        const areaNameUpdate = formData.map((item, index) => {
            if (!item.name) {
                return {
                    ...item,
                    name: `Area ${index + 1}`,
                };
            }
            return item;
        });
        setFormData(areaNameUpdate);
    };

    return (
        <Card className="absolute w-[320px] h-[596px] top-[52px] left-[12px] rounded-3xl px-6 py-5 gap-3 bg-white area-detail-block">
            <div>
                <div className='flex flex-row items-start absolute pt-6 pr-6 pb-0 pl-0 w-11 h-11 right-[-1px] top-0 gap-[10px]'>
                    <CloseIcon color='#3D3D3C' className='w-5 h-5 cursor-pointer' onClick={() => handleOnClose()} />
                </div>
                <div className='flex flex-col items-start absolute p-0 gap-3'>
                    <Text className='mb-3 text-xl font-husqvarna not-italic font-bold text-secondary'>
                        Area details
                    </Text>
                    <Text className='flex items-center tracking-[0.4px] text-sm font-roboto not-italic font-normal text-blurred'>
                        {formData.find(area => area.id === areaIndex)?.area_sqr_mtr} m<sup>2</sup>
                    </Text>
                </div>
                <div className='flex flex-col absolute w-[272px] h-[528px] pt-8 gap-6 top-[84px] left-[24px]'>
                    <form>
                        <div className='flex flex-col items-start absolute p-0 gap-4 w-[272px] h-[56px] top-[32px]'>
                            <Text className='flex flex-row items-start absolute text-sm font-roboto not-italic font-normal text-blurred px-1 top-[-8px] left-3 z-10 h-[20px] bg-white gap-[10px]'>Area name</Text>
                            <input type="text" name="name" value={formData.find(area => area.id === areaIndex)?.name || ''} onChange={handleInputChange} className='absolute font-roboto not-italic font-normal text-lg px-4 w-[272px] h-[48px] rounded-xl box-border border border-solid bg-white border-transparent text-secondary focus:border-[#3D3D3C] focus:border-1 focus:outline-none' />
                        </div>
                        <div className='flex flex-col items-start absolute w-[272px] h-[68px] p-0 gap-2 top-[104px]'>
                            <Text className='h-[20px] text-sm font-roboto not-italic font-normal tracking-[0.4px] text-blurred'>
                                Grass quality
                            </Text>
                            <div className='flex flex-col items-start p-0 gap-2 w-[240px] h-[40px] top-[28px]'>
                                <div className='flex flex-row items-start p-0 gap-2 w-[240px] h-[40px]'>
                                    <Badge
                                        title='Regular'
                                        status={formData.find(area => area.id === areaIndex)?.cuttingQuality === 1}
                                        onClick={() => handleGrassQChange(1)}
                                    />
                                    <Badge
                                        title='Pro'
                                        status={formData.find(area => area.id === areaIndex)?.cuttingQuality === 2}
                                        onClick={() => handleGrassQChange(2)}
                                    />
                                    <Badge
                                        title='Pro sports'
                                        status={formData.find(area => area.id === areaIndex)?.cuttingQuality === 3}
                                        onClick={() => handleGrassQChange(3)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col items-start absolute pt-3 gap-8 w-[272px] h-[292px] top-[196px]'>
                            <div className='flex flex-col items-start absolute p-0 gap-4 w-[272px] h-[48px] top-[12px]'>
                                <Text className='flex flex-row items-start absolute text-sm font-roboto not-italic font-normal text-blurred px-1 top-[-8px] left-3 z-10 h-[20px] bg-white gap-[10px]'>Maximum slope (degrees)</Text>
                                <input type="number" name="slope" value={formData.find(area => area.id === areaIndex)?.slope || ''} onChange={handleInputChange} className='absolute font-roboto not-italic font-normal text-lg px-4 w-[272px] h-[48px] rounded-xl box-border border border-solid bg-white border-transparent text-secondary focus:border-[#3D3D3C] focus:border-1 focus:outline-none' min="0" max="360" />
                            </div>
                            <div className='flex flex-col items-start absolute p-0 gap-4 w-[272px] h-[56px] top-[92px]'>
                                <Text className='flex flex-row items-start absolute text-sm font-roboto not-italic font-normal text-blurred px-1 top-[-8px] left-3 z-10 h-[20px] bg-white gap-[10px]'>Available hours to cut per day</Text>
                                <input type="number" name="available_hours" value={formData.find(area => area.id === areaIndex)?.available_hours || ''} onChange={handleInputChange} className='absolute font-roboto not-italic font-normal text-lg px-4 w-[272px] h-[48px] rounded-xl box-border border border-solid bg-white border-transparent text-secondary focus:outline-none focus:border-[#3D3D3C] focus:border-1' min="0" max="24" />
                            </div>
                            <div className='flex flex-col items-start absolute p-0 gap-4 w-[272px] h-[120px] top-[172px]'>
                                <Text className='flex flex-row items-start absolute text-sm font-roboto not-italic font-normal text-blurred px-1 top-[-8px] left-3 z-10 h-[20px] bg-white gap-[10px]'>Write a note</Text>
                                <textarea rows={4} name="note" value={formData.find(area => area.id === areaIndex)?.note || ''} onChange={handleInputChange} className='absolute font-roboto not-italic font-normal text-lg py-1 px-4 w-[272px] h-[120px] rounded-xl box-border border border-solid bg-white border-transparent text-secondary focus:outline-none focus:border-[#3D3D3C] focus:border-1'>
                                </textarea>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Card>
    );
}
export default AreaForm;