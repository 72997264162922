import React from 'react';
import { IClassName } from '../../app/interfaces';

// ============================= SaveIcon ===========================//

export function SaveIcon({ className, onClick, color }: IClassName) {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.11252 1.73497C2.35172 1.73497 1.73497 2.35172 1.73497 3.11252L1.73497 16.888C1.73497 17.6488 2.35172 18.2656 3.11252 18.2656H3.8013L3.8013 13.4442C3.8013 11.9226 5.03479 10.6891 6.55639 10.6891H13.4441C14.9657 10.6891 16.1992 11.9226 16.1992 13.4442L16.1992 18.2656H16.888C17.6488 18.2656 18.2656 17.6488 18.2656 16.888L18.2656 5.63128C18.2656 5.08325 18.0479 4.55767 17.6604 4.17016L16.7454 3.25517L15.8304 2.34019C15.5513 2.06115 15.2007 1.87017 14.8217 1.7851V5.52324C14.8217 7.04484 13.5882 8.27834 12.0666 8.27834L6.55639 8.27834C5.03479 8.27834 3.8013 7.04484 3.8013 5.52324L3.8013 1.73497H3.11252ZM14.8217 18.2656L5.17884 18.2656L5.17884 13.4442C5.17884 12.6834 5.79559 12.0666 6.55639 12.0666H13.4441C14.2049 12.0666 14.8217 12.6834 14.8217 13.4442L14.8217 18.2656ZM5.17884 1.73497L13.4441 1.73497V5.52324C13.4441 6.28404 12.8274 6.90079 12.0666 6.90079L6.55639 6.90079C5.79559 6.90079 5.17884 6.28404 5.17884 5.52324L5.17884 1.73497ZM0.357422 3.11252C0.357422 1.59092 1.59092 0.357422 3.11252 0.357422L14.3693 0.357422C15.2826 0.357422 16.1586 0.720258 16.8045 1.36611L17.7194 2.2811L18.6344 3.19609C19.2803 3.84194 19.6431 4.7179 19.6431 5.63128L19.6431 16.888C19.6431 18.4096 18.4096 19.6431 16.888 19.6431L3.11252 19.6431C1.59092 19.6431 0.357422 18.4096 0.357422 16.888L0.357422 3.11252ZM12.411 3.11252C12.411 2.73212 12.1026 2.42375 11.7222 2.42375C11.3418 2.42375 11.0334 2.73212 11.0334 3.11252V5.17885C11.0334 5.55925 11.3418 5.86763 11.7222 5.86763C12.1026 5.86763 12.411 5.55925 12.411 5.17885V3.11252Z"
                fill={color}
            />
        </svg>
    );
}

// ============================= SearchIcon ===========================//

export function SearchIcon({ className, onClick, color }: IClassName) {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <rect width="48" height="48" rx="12" />
            <path
                d="M28.0092 29.5069L28.0765 29.5664L33.7908 35.2806C34.0697 35.5596 34.0697 36.0118 33.7908 36.2908C33.5333 36.5483 33.1281 36.5681 32.8479 36.3502L32.7806 36.2908L27.0663 30.5765C26.7874 30.2976 26.7874 29.8453 27.0663 29.5664C27.3238 29.3089 27.729 29.2891 28.0092 29.5069ZM20.7857 16.5C24.5334 16.5 27.5714 19.5381 27.5714 23.2857C27.5714 27.0334 24.5334 30.0714 20.7857 30.0714C17.0381 30.0714 14 27.0334 14 23.2857C14 19.5381 17.0381 16.5 20.7857 16.5ZM20.7857 17.9286C17.827 17.9286 15.4286 20.327 15.4286 23.2857C15.4286 26.2444 17.827 28.6428 20.7857 28.6428C23.7444 28.6428 26.1429 26.2444 26.1429 23.2857C26.1429 20.327 23.7444 17.9286 20.7857 17.9286Z"
                fill={color}
            />
        </svg>
    );
}

// ============================= PenIcon ===========================//

export function MagicPenIcon({ className, onClick, color }: IClassName) {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <rect width="48" height="48" />
            <g clipPath="url(#clip0_4398_3835)">
                <path d="M29.1709 14.2021C29.2478 14.0485 29.4671 14.0485 29.5439 14.2021L30.3093 15.7328C30.3294 15.7732 30.3622 15.8059 30.4025 15.8261L31.9332 16.5914C32.0869 16.6683 32.0869 16.8876 31.9332 16.9644L30.4025 17.7298C30.3622 17.7499 30.3294 17.7827 30.3093 17.823L29.5439 19.3537C29.4671 19.5074 29.2478 19.5074 29.1709 19.3537L28.4056 17.823C28.3854 17.7827 28.3527 17.7499 28.3123 17.7298L26.7816 16.9644C26.628 16.8876 26.628 16.6683 26.7816 16.5914L28.3123 15.8261C28.3527 15.8059 28.3854 15.7732 28.4056 15.7328L29.1709 14.2021Z" fill={color} />
                <path d="M31.6142 19.9784C31.7144 19.778 32.0004 19.778 32.1006 19.9784L32.5646 20.9064C32.5909 20.959 32.6336 21.0017 32.6862 21.028L33.6141 21.4919C33.8145 21.5922 33.8145 21.8781 33.6141 21.9783L32.6862 22.4423C32.6336 22.4686 32.5909 22.5113 32.5646 22.5639L32.1006 23.4919C32.0004 23.6923 31.7144 23.6923 31.6142 23.4919L31.1503 22.5639C31.1239 22.5113 31.0813 22.4686 31.0287 22.4423L30.1007 21.9783C29.9003 21.8781 29.9003 21.5922 30.1007 21.4919L31.0287 21.028C31.0813 21.0017 31.1239 20.959 31.1503 20.9064L31.6142 19.9784Z" fill={color} />
                <path d="M23.2289 14.1563C23.2883 14.0375 23.4578 14.0375 23.5172 14.1563L23.9823 15.0864C23.9979 15.1176 24.0232 15.1429 24.0544 15.1585L24.9845 15.6236C25.1033 15.683 25.1033 15.8525 24.9845 15.912L24.0544 16.377C24.0232 16.3926 23.9979 16.4179 23.9823 16.4491L23.5172 17.3793C23.4578 17.4981 23.2883 17.4981 23.2289 17.3793L22.7638 16.4491C22.7482 16.4179 22.7229 16.3926 22.6917 16.377L21.7616 15.912C21.6427 15.8525 21.6427 15.683 21.7616 15.6236L22.6917 15.1585C22.7229 15.1429 22.7482 15.1176 22.7638 15.0864L23.2289 14.1563Z" fill={color} />
                <path fillRule="evenodd" clipRule="evenodd" d="M28.6148 24.2092C28.8937 23.9303 29.346 23.9303 29.625 24.2092L32.125 26.7092C32.4039 26.9882 32.4039 27.4404 32.125 27.7194L26.2916 33.5527C26.0127 33.8316 25.5604 33.8316 25.2815 33.5527L22.7815 31.0527C22.5025 30.7737 22.5025 30.3215 22.7815 30.0425L28.6148 24.2092ZM24.2967 30.5476L25.7865 32.0375L30.6097 27.2143L29.1199 25.7244L24.2967 30.5476Z" fill={color} />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.4475 15.8772C14.6276 15.6971 14.89 15.6265 15.1361 15.692L26.3265 18.6677C26.556 18.7287 26.7402 18.8999 26.8179 19.1244L28.9609 25.3153C29.0504 25.5738 28.9844 25.8606 28.791 26.054L24.6243 30.2207C24.4309 30.4141 24.144 30.4801 23.8855 30.3906L17.695 28.2473C17.4705 28.1696 17.2994 27.9854 17.2384 27.7559L14.2623 16.5659C14.1968 16.3197 14.2674 16.0573 14.4475 15.8772ZM15.9595 17.3891L18.5213 27.0216L23.9304 28.8943L27.4646 25.36L25.5922 19.9507L15.9595 17.3891Z" fill={color} />
                <path fillRule="evenodd" clipRule="evenodd" d="M22.4532 22.9286C21.9272 22.9286 21.5008 23.355 21.5008 23.881C21.5008 24.4069 21.9272 24.8333 22.4532 24.8333C22.9792 24.8333 23.4056 24.4069 23.4056 23.881C23.4056 23.355 22.9792 22.9286 22.4532 22.9286ZM20.0723 23.881C20.0723 22.566 21.1383 21.5 22.4532 21.5C23.7682 21.5 24.8342 22.566 24.8342 23.881C24.8342 25.1959 23.7682 26.2619 22.4532 26.2619C21.1383 26.2619 20.0723 25.1959 20.0723 23.881Z" fill={color} />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.4475 15.8772C14.7264 15.5982 15.1787 15.5982 15.4576 15.8772L21.7793 22.1988C22.0582 22.4778 22.0582 22.9301 21.7793 23.209C21.5004 23.4879 21.0481 23.4879 20.7692 23.209L14.4475 16.8873C14.1685 16.6084 14.1685 16.1561 14.4475 15.8772Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_4398_3835">
                    <rect width="20" height="20" transform="translate(14 14)" />
                </clipPath>
            </defs>
        </svg>
    );
}

// ============================= PenIcon ===========================//

export function PenIcon({ className, onClick, color }: IClassName) {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <rect
                width="48"
                height="48"
                rx="12"
            />
            <path
                d="M24 32.3333L29.8333 26.5L32.3333 29L26.5 34.8333L24 32.3333Z"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M29.0003 27.3327L27.7503 21.0827L15.667 18.166L18.5837 30.2493L24.8337 31.4993L29.0003 27.3327Z"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.667 18.166L21.9887 24.4877"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.1667 27.3333C24.0871 27.3333 24.8333 26.5871 24.8333 25.6667C24.8333 24.7462 24.0871 24 23.1667 24C22.2462 24 21.5 24.7462 21.5 25.6667C21.5 26.5871 22.2462 27.3333 23.1667 27.3333Z"
                stroke={color}
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

// ============================= LayerIcon ===========================//

export function LayerIcon({ className, onClick, color }: IClassName) {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <rect width="48" height="48" rx="10" />
            <g clipPath="url(#clip0_3639_353)">
                <path
                    d="M33.9198 28.3687C34.0886 28.6987 33.983 29.0996 33.6867 29.3029L33.615 29.3462L25.6459 33.5462C24.6715 34.0598 23.522 34.0883 22.5281 33.6318L22.3544 33.5462L14.3853 29.3462C14.0352 29.1617 13.8987 28.7241 14.0805 28.3687C14.2493 28.0387 14.6331 27.8957 14.9674 28.0247L15.0436 28.0593L23.0127 32.2593C23.5875 32.5623 24.2639 32.5839 24.8533 32.3242L24.9876 32.2593L32.9567 28.0593C33.3068 27.8748 33.738 28.0133 33.9198 28.3687ZM33.9198 24.7436C34.0886 25.0736 33.983 25.4745 33.6867 25.6779L33.615 25.7212L25.6459 29.9211C24.6715 30.4347 23.522 30.4632 22.5281 30.0067L22.3544 29.9211L14.3853 25.7212C14.0352 25.5367 13.8987 25.099 14.0805 24.7436C14.2493 24.4136 14.6331 24.2706 14.9674 24.3996L15.0436 24.4342L23.0127 28.6342C23.5875 28.9372 24.2639 28.9588 24.8533 28.6991L24.9876 28.6342L32.9567 24.4342C33.3068 24.2497 33.738 24.3882 33.9198 24.7436ZM22.3371 14.4429C23.4064 13.8615 24.6883 13.852 25.749 14.4175L32.8754 18.2169C33.921 18.7744 34.3041 20.0808 33.7309 21.1348C33.5279 21.5083 33.2206 21.8156 32.8471 22.0187L25.6632 25.9248C24.5939 26.5062 23.312 26.5157 22.2513 25.9502L15.1249 22.1508C14.0793 21.5933 13.6962 20.287 14.2694 19.2329C14.4724 18.8594 14.7798 18.5521 15.1532 18.349L22.3371 14.4429ZM25.0572 15.6898C24.4207 15.3505 23.6516 15.3562 23.01 15.7051L15.8262 19.6112C15.7017 19.6789 15.5992 19.7813 15.5315 19.9058C15.3405 20.2572 15.4682 20.6926 15.8167 20.8785L22.9432 24.6779C23.5796 25.0172 24.3487 25.0115 24.9903 24.6626L32.1742 20.7565C32.2987 20.6888 32.4011 20.5864 32.4688 20.4619C32.6598 20.1105 32.5321 19.6751 32.1836 19.4893L25.0572 15.6898Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_3639_353">
                    <rect width="20" height="20" transform="translate(14 14)" />
                </clipPath>
            </defs>
        </svg>
    );
}

// ============================= PlusIcon ===========================//

export function PlusIcon({ className, onClick, color }: IClassName) {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <rect width="48" height="48" rx="10" />
            <path
                d="M24 14C24.3663 14 24.6682 14.2758 24.7095 14.631L24.7143 14.7143V23.2857L33.2857 23.2857C33.6802 23.2857 34 23.6055 34 24C34 24.3663 33.7243 24.6682 33.369 24.7095L33.2857 24.7143L24.7143 24.7143L24.7143 33.2857C24.7143 33.6802 24.3945 34 24 34C23.6337 34 23.3318 33.7243 23.2905 33.369L23.2857 33.2857L23.2857 24.7143H14.7143C14.3198 24.7143 14 24.3945 14 24C14 23.6337 14.2757 23.3318 14.631 23.2905L14.7143 23.2857H23.2857V14.7143C23.2857 14.3198 23.6055 14 24 14Z"
                fill={color}
            />
        </svg>
    );
}

// ============================= MinusIcon ===========================//

export function MinusIcon({ className, onClick, color }: IClassName) {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <rect width="48" height="48" rx="10" />
            <path
                d="M14 23.9994C14 23.6331 14.2757 23.3312 14.631 23.29L14.7143 23.2852L33.2857 23.2852C33.6802 23.2852 34 23.605 34 23.9994C34 24.3658 33.7243 24.6677 33.369 24.7089L33.2857 24.7137L14.7143 24.7137C14.3198 24.7137 14 24.3939 14 23.9994Z"
                fill={color}
            />
        </svg>
    );
}

// ============================= CloseIcon ===========================//

export function CloseIcon({ className, onClick, color }: IClassName) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <path
                d="M0.738659 0.739636C0.944654 0.533642 1.26879 0.517796 1.49296 0.692099L1.5468 0.739636L15.2613 14.4542C15.4845 14.6773 15.4845 15.0391 15.2613 15.2623C15.0553 15.4683 14.7312 15.4841 14.507 15.3098L14.4532 15.2623L0.738659 1.54777C0.515499 1.32461 0.515499 0.962797 0.738659 0.739636Z"
                fill={color}
            />
            <path
                d="M15.2613 0.739636C15.0553 0.533642 14.7312 0.517796 14.507 0.692099L14.4532 0.739636L0.738687 14.4542C0.515526 14.6773 0.515526 15.0391 0.738687 15.2623C0.94468 15.4683 1.26882 15.4841 1.49299 15.3098L1.54682 15.2623L15.2613 1.54777C15.4845 1.32461 15.4845 0.962797 15.2613 0.739636Z"
                fill={color}
            />
        </svg>
    );
}

// ============================= TrashIcon ===========================//

export function TrashIcon({ className, onClick, color }: IClassName) {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <g clipPath="url(#clip0_3821_758)">
                <path
                    d="M7.07171 0C7.98979 0 8.73931 0.721701 8.7839 1.62873L8.78599 1.71429V1.92857H11.1431C11.3798 1.92857 11.5717 2.12045 11.5717 2.35714C11.5717 2.57693 11.4063 2.75807 11.1931 2.78283L11.1431 2.78571H10.5003V10.2857L10.4982 10.3713C10.4536 11.2783 9.70408 12 8.78599 12H3.64314L3.55758 11.9979C2.65055 11.9533 1.92885 11.2038 1.92885 10.2857V2.78571H1.28599C1.0493 2.78571 0.857422 2.59384 0.857422 2.35714C0.857422 2.13736 1.02287 1.95621 1.23601 1.93145L1.28599 1.92857H3.64314V1.71429C3.64314 0.796202 4.36484 0.0466805 5.27186 0.00209799L5.35742 0H7.07171ZM9.64314 2.78571H2.78599V10.2857L2.78834 10.3497C2.82107 10.7932 3.19127 11.1429 3.64314 11.1429H8.78599L8.84996 11.1405C9.29347 11.1078 9.64314 10.7376 9.64314 10.2857V2.78571ZM7.07171 0.857143H5.35742C4.90555 0.857143 4.53535 1.2068 4.50263 1.65032L4.50028 1.71429V1.92857H7.92885V1.71429C7.92885 1.26242 7.57919 0.892215 7.13568 0.859494L7.07171 0.857143Z"
                    fill={color}
                />
            </g>
        </svg>
    );
}

// ============================= InfoIcon ===========================//

export function InfoIcon({ className, color }: IClassName) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1.14291C4.21293 1.14291 1.14291 4.21293 1.14291 8C1.14291 11.7871 4.21293 14.8571 8 14.8571C11.7871 14.8571 14.8571 11.7871 14.8571 8C14.8571 4.21293 11.7871 1.14291 8 1.14291Z"
                fill={color}
            />
            <path
                d="M7.99919 6.28516C8.29225 6.28516 8.53379 6.50576 8.5668 6.78997L8.57064 6.85661V11.9997C8.57064 12.3153 8.31479 12.5711 7.99919 12.5711C7.70613 12.5711 7.46459 12.3505 7.43158 12.0663L7.42773 11.9997V6.85661C7.42773 6.541 7.68358 6.28516 7.99919 6.28516Z"
                fill={color}
            />
            <path
                d="M7.99977 5.14408C8.3157 5.14408 8.57181 4.88797 8.57181 4.57204C8.57181 4.25611 8.3157 4 7.99977 4C7.68385 4 7.42773 4.25611 7.42773 4.57204C7.42773 4.88797 7.68385 5.14408 7.99977 5.14408Z"
                fill={color}
            />
        </svg>

    );
}