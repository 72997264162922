import React from 'react';
import { IBadge } from '../app/interfaces';
import { clsxm } from '../app/utils';
import { Text } from '../component';

export function Badge({ title, status, onClick, className }: IBadge) {
    return (
        <div className={clsxm(
            'flex flex-row items-start pt-3 px-4 rounded-2xl cursor-pointer gap-[10px] h-[40px]',
            status ? ['justify-center bg-primary'] : ['box-border border border-solid border-blurred'],
            className
        )} onClick={onClick}>
            <Text className={clsxm('text-xs text-center font-roboto not-italic font-normal tracking-[0.4px]', status ? ['text-white'] : ['text-secondary'])}>
                {title}
            </Text>
        </div>
    );
}
