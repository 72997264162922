import React, { PropsWithChildren } from 'react';
import { clsxm } from '../app/utils';

type Props = PropsWithChildren<{ children: React.ReactNode, className: string }> &
  React.ComponentPropsWithRef<'div'>;

export function Card({ children, className, ...rest }: Props) {
  return (
    <div className={clsxm('bg-hover', className)}
      {...rest}
    >
      {children}
    </div>
  );
}
