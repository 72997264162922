import React from 'react';
export function Loader(props: { size?: number, showOverlapper?: boolean }) {
    return (
        <>
            <div className={`h-full w-full top-0 left-0 ${props.showOverlapper ? 'bg-black bg-opacity-75' : ''} z-10 absolute`}>
                <div className="h-full w-full flex justify-center items-center">
                    <div className="loader loader--style2">
                        <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            width={props.size || 80} height={props.size || 80} viewBox="0 0 50 50" xmlSpace="preserve">
                            <path fill="#fff" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
                                <animateTransform attributeType="xml"
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 25 25"
                                    to="360 25 25"
                                    dur="0.6s"
                                    repeatCount="indefinite" />
                            </path>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    )
}