import mapboxgl, { LngLatLike } from 'mapbox-gl';
import { IAddPopup, IArea, IPopup } from '../../app/interfaces';

const addPopup = ({ map }: IAddPopup) => {
  if (map.current) {
    const popup: mapboxgl.Popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: 'bottom-left',
      className: 'area-popup-head'
    }).addTo(map.current);

    return popup;
  }
};

const getQuality = (value: 1 | 2 | 3) => {
  switch (value) {
    case 1: return 'Regular';
    case 2: return 'Pro';
    case 3: return 'Pro sports';
  }
}

export const updatePopup = (popup: IPopup | undefined, formData: IArea | undefined, longlat: LngLatLike): mapboxgl.Popup | undefined => {
  if (popup) {
    longlat && popup.popup.setLngLat(longlat);
    popup.popup.setHTML(getHTML(popup.id, formData));
    return popup.popup;
  }
}

const getHTML = (index: number, areaData: IArea | undefined): string => {
  return `<div class='area-popup-main-div'>
            <div class='trash-icon-div'>
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                id='trash_icon_${index}'
              >
                <g clip-path="url(#clip0_4398_1643)">
                    <path d="M9.42828 0.5C10.6524 0.5 11.6518 1.46227 11.7112 2.67163L11.714 2.78571V3.07143H14.8568C15.1724 3.07143 15.4283 3.32727 15.4283 3.64286C15.4283 3.93591 15.2077 4.17743 14.9235 4.21044L14.8568 4.21429H13.9997V14.2143L13.9969 14.3284C13.9375 15.5377 12.9381 16.5 11.714 16.5H4.85686L4.74278 16.4972C3.53341 16.4378 2.57115 15.4384 2.57115 14.2143V4.21429H1.71401C1.39842 4.21429 1.14258 3.95845 1.14258 3.64286C1.14258 3.34981 1.36317 3.10828 1.64737 3.07527L1.71401 3.07143H4.85686V2.78571C4.85686 1.5616 5.81913 0.562241 7.02849 0.502797L7.14257 0.5H9.42828ZM12.8569 4.21429H3.714V14.2143L3.71714 14.2996C3.76077 14.8909 4.25437 15.3571 4.85686 15.3571H11.714L11.7993 15.354C12.3906 15.3104 12.8569 14.8168 12.8569 14.2143V4.21429ZM9.42828 1.64286H7.14257C6.54008 1.64286 6.04648 2.10907 6.00285 2.70042L5.99972 2.78571V3.07143H10.5711V2.78571C10.5711 2.18322 10.1049 1.68962 9.51358 1.64599L9.42828 1.64286Z" fill="#3D3D3C"/>
                </g>
                <defs>
                    <clipPath id="clip0_4398_1643">
                        <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                    </clipPath>
                </defs>
              </svg>
            </div>
            <div class='area-popup-div-${index} area-popup-div-common' id='area_div_${index}'>
              <div id='area_popup_div_${index}'>
                <div class='area-popup-div-block'>
                  <p class='area-popup-main-title'>${areaData?.name}</p>
                  <p class='area-popup-sqaure-meter'><span>${areaData?.area_sqr_mtr}</span> m<sup>2</sup></p>
                  <div class='area-facts-block area-facts-block-${areaData?.area_id}'>
                    <p>${getQuality(areaData?.cuttingQuality || 1)} quality</p>
                    <span></span>
                    <p>${areaData?.slope || 0} % slope</p>
                    <span></span>
                    <p>Available ${areaData?.available_hours || 24} h</p>
                  </div>
                  ${areaData?.note ? `<div class='area-facts-block area-note-block area-facts-block-${areaData?.area_id}'>
                    <p class='area-note-text' title='${areaData.note}'>${areaData.note}</p>
                  </div>` : ''}
                </div>
              </div>
            </div>
          </div>`;
}

export default addPopup;