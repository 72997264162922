import React, { useEffect, useRef, useState } from 'react';
import mapboxgl, { GeoJSONSource, Map } from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { ConfirmationModal, Text } from '../component';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxDraw, { DrawModes } from '@mapbox/mapbox-gl-draw';
import { area, polygon, center, Feature, Polygon } from '@turf/turf';
import { IArea, IConfirm, IDrawnArea, IHusqvarnamapProps } from '../app/interfaces';
import AreaForm from '../component/pages/AreaForm';
import Sidebar from '../component/layouts/Sidebar';
import Header from '../component/layouts/Header';
import addPopup, { updatePopup } from '../component/shared/AddPopup';
import updateMarkers from '../component/shared/UpdateMarkers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Infobox from '../component/pages/InfoBox';
import axios from 'axios';
import { Loader } from '../component/loader';
import { ArrowTooltip } from '../component/arrow-tooltip';
import { InfoIcon } from '../component/svg';
import { saveAs } from 'file-saver';

const handleMouseMove = (e: any) => {
  document.querySelectorAll('.tooltip').forEach((item: any) => {
    item.style.display = 'block';
    // calculate the initial position of the tooltip
    let tooltipX = e.clientX + 35; // add an offset of 35px from the cursor
    let tooltipY = e.clientY - 20; // subtract an offset of 20px from the cursor
    // check if the tooltip is going off the right side of the screen
    if (tooltipX + item.clientWidth > window.innerWidth) {
      tooltipX = e.clientX - item.clientWidth - 35; // adjust to the left side of the cursor
    }
    // check if the tooltip is going off the bottom side of the screen
    if (tooltipY + item.clientHeight > window.innerHeight) {
      tooltipY = window.innerHeight - item.clientHeight - 20; // adjust to stay within the window height
    }
    // set the new position of the tooltip
    item.style.left = tooltipX + 'px';
    item.style.top = tooltipY + 'px';
  });
}

const Husqvarnamap = (props: IHusqvarnamapProps) => {
  const { access_token, project_name, api_end_point, savedJSON, downloadable } = props;

  const map = useRef<Map | null>(null);
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const drawControlRef = useRef<MapboxDraw | null>(null);
  const popups = useRef<Array<{ id: number; areaId: string, popup: mapboxgl.Popup }>>([]);

  const [activeMenu, setActiveMenu] = useState<string>('');
  const activeMenuRef = useRef(activeMenu);

  const [formData, setFormData] = useState<IArea[]>([]);
  const formDataRef = useRef<IArea[] | []>([]);

  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [geocoderControl, setGeocoderControl] = useState<MapboxGeocoder | undefined>();
  const [zoom, setZoom] = useState<number | undefined>(13);
  const [centered, setCentered] = useState<{ longitude: number; latitude: number }>({ longitude: 11.967017, latitude: 57.707233 });
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [areaIndex, setAreaIndex] = useState<number>(0);
  const [confirmModal, setConfirmModal] = useState<IConfirm>({ showModal: false, areaId: null, polygonId: '' });
  const [siteInfo, setSiteInfo] = useState<boolean>(true);
  const [autoMapInfo, setAutoMapInfo] = useState<boolean>(false);
  const [foundAreaInfo, setFoundAreaInfo] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [doneLoading, setDoneLoading] = useState<boolean>(false);
  const [doneBtn, setDoneBtn] = useState<boolean>(false);

  const markerpin = useRef<mapboxgl.Marker | null>(null);
  const drawClustering = useRef<any>({ 'features': [] });
  const drawIndex = useRef(0);
  const areaRef = useRef<IArea | undefined>();

  activeMenuRef.current = activeMenu;
  formDataRef.current = formData;

  const setPopups = (array: any[]) => {
    popups.current = array;
  }

  const updatePolygonColors = (areaId: string, colorHexCode: string) => {
    map.current?.setPaintProperty(`gl-draw-polygon-fill-${areaId}`, 'fill-color', colorHexCode);
    map.current?.setPaintProperty(`gl-draw-polygon-fill-${areaId}`, 'fill-outline-color', colorHexCode);
    map.current?.setPaintProperty(`gl-draw-polygon-stroke-active-${areaId}`, 'line-color', colorHexCode);
  }

  const addResources = (id: any, coords: any) => {
    map.current?.addSource(`source-${id}`, {
      type: 'geojson',
      data: {
        'type': 'FeatureCollection',
        'features': [
          {
            'type': 'Feature',
            'geometry': {
              'type': 'Polygon',
              'coordinates': [coords],
            },
            'properties': {}
          }]
      }
    });
    // create layer for clustering polygons start
    map.current?.addLayer({
      'id': `clustering-layer-${id}`,
      'type': 'circle',
      'filter': ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
      'source': 'polygon-source',
      'paint': {
        'circle-radius': 6,
        'circle-color': '#FFC800',
      },
    });
    // create layer for clustering polygons end
    map.current?.addLayer({
      'id': `gl-draw-polygon-fill-${id}`,
      'type': 'fill',
      'filter': ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
      'source': `source-${id}`,
      'layout': {},
      'paint': {
        'fill-color': '#FFFFFF',
        'fill-outline-color': '#FFFFFF',
        'fill-opacity': 0.4
      }
    });
    map.current?.addLayer({
      'id': `gl-draw-polygon-fill-inactive-${id}`,
      'type': 'fill',
      'filter': ['all', ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static']
      ],
      'source': `source-${id}`,
      'layout': {},
      'paint': {
        'fill-color': '#0FC2DA',
        'fill-outline-color': '#0FC2DA',
        'fill-opacity': 0.5
      }
    });
    map.current?.addLayer({
      'id': `gl-draw-polygon-stroke-active-${id}`,
      'type': 'line',
      'filter': ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
      'source': `source-${id}`,
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#FFFFFF',
        'line-width': 3
      }
    });
    map.current?.addLayer({
      'id': `gl-draw-polygon-stroke-inactive-${id}`,
      'type': 'line',
      'filter': ['all', ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static']
      ],
      'source': `source-${id}`,
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#0FC2DA',
        'line-width': 3
      }
    });
    updatePolygonColors(id, '#0FC2DA');
  }

  const calculateArea = (param: any[]): number => {
    // calculate polygon area in sqr mtr
    const drawnPolygon: Feature<Polygon> = polygon([param]);
    // default in square meters, we can change using { units: 'kilometers' }
    const sqrmtr = area(drawnPolygon);
    return Math.floor(sqrmtr);
  }

  const getLngLat = (param: any): any => {
    // calcualte the center of drawn area
    const centerPoint = center(param);
    // use center as latitude & longitude of drawn area
    return centerPoint.geometry.coordinates;
  }

  const onMouseEnterHandler = (areaId: any) => {
    if (!formVisible) {
      updatePolygonColors(areaId, '#FFFFFF');
    } else {
      updatePolygonColors(areaId, 'transparent');
    }
    document.querySelectorAll(`.area-facts-block-${areaId}`)?.forEach((item) => {
      item.classList.add('popup-hover-on');
    });
  };

  const onMouseLeaveHandler = (areaId: any) => {
    if (!formVisible) {
      updatePolygonColors(areaId, '#0FC2DA');
    } else {
      updatePolygonColors(areaId, '#FFFFFF');
    }
    document.querySelectorAll(`.area-facts-block-${areaId}`)?.forEach((item) => {
      item.classList.remove('popup-hover-on');
    });
  };

  const removeExtraDrawnArea = () => {
    drawControlRef.current?.getAll().features.forEach((item) => {
      if (item.id !== areaRef?.current?.id && !formData.some((data) => item.id === data.area_id)) {
        drawControlRef.current?.delete(item.id?.toString() || '');
      }
    })
  }

  const handleManualAreaDraw = (area: any) => {
    drawIndex.current++;

    const id = area.area_id || area.id;
    const areaSqrMtr = calculateArea(area.geometry.coordinates[0]);

    // set polygon id to send it to areaform
    setConfirmModal({ showModal: false, areaId: null, polygonId: id });

    // create popup for each polygon on center point
    const longlat = getLngLat(area.geometry);

    // create the object to push into clustering features
    const objectToPush: IDrawnArea = { 'geometry': { 'coordinates': [] }, 'id': `feature-${id}` };
    objectToPush.geometry.coordinates.push(longlat[0], longlat[1]);
    const existingDrawClustering = { ...drawClustering.current };
    existingDrawClustering.features.push(objectToPush);
    // setDrawClustering(existingDrawClustering)
    drawClustering.current = existingDrawClustering;

    // get source to set the clustering geo JSON data to source
    const source: GeoJSONSource = map.current?.getSource('polygon-source') as GeoJSONSource;
    source.setData(existingDrawClustering);

    // add layer (div) with particular id or class name that we can use for each different polygon 
    addResources(id, area.geometry.coordinates[0]);

    // add steady popup for individual polygon area
    const popup = addPopup({ map });
    setPopups([...popups.current, { id: drawIndex.current, areaId: id, popup }])

    // create a blank object for area detail form on draw of polygon
    const formObj: IArea = {
      id: drawIndex.current,
      area_sqr_mtr: Math.round(area.area_sqr_mtr) || areaSqrMtr,
      area_id: id,
      name: 'Area ' + drawIndex.current,
      cuttingQuality: area.grass_quality || 1,
      slope: area.max_slope || 0,
      available_hours: area.available_hours || '',
      note: area.note || '',
      geometry: {
        type: 'Polygon',
        coordinates: area.geometry.coordinates[0],
      },
      unitSystem: '0'
    };

    // add object in form data state
    setFormData(prevItems => [...prevItems, formObj]);

    // draw area active on click and hover color change start
    map.current?.on('mouseenter', `gl-draw-polygon-fill-${id}`, () => onMouseEnterHandler(id));
    map.current?.on('mouseleave', `gl-draw-polygon-fill-${id}`, () => onMouseLeaveHandler(id));
    // draw area active on click of polygon and hover color change end
  }

  const handleAutoAreaDraw = (area: any) => {
    areaRef.current = {
      id: area.id,
      name: 'Area',
      unitSystem: '0',
      area_id: area.id,
      area_sqr_mtr: calculateArea(area.geometry.coordinates[0]),
      cuttingQuality: 1,
      slope: 0,
      geometry: {
        coordinates: area.geometry.coordinates[0],
      },
    };

    removeExtraDrawnArea();
  }

  useEffect(() => {
    mapboxgl.accessToken = access_token;
    if (mapContainer.current) {
      // initilize the Map
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/' + 'satellite-streets-v12',
        center: [centered.longitude, centered.latitude],
        zoom: zoom,
        preserveDrawingBuffer: true,
        attributionControl: false,
      });
      // initially get the user's current location on map
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { longitude, latitude } = position.coords;
            map.current?.setCenter([longitude, latitude]);
            setCentered({ longitude: longitude, latitude: latitude });
            // add the control to set marker on the map
            if (map.current && !markerpin.current) {
              markerpin.current = new mapboxgl.Marker({ color: '#FFC800' })
                .setLngLat([longitude, latitude])
                .addTo(map.current);
            }
          },
          (error) => {
            console.log('Error getting user location:', error);
          }
        );
      } else {
        console.log('Geolocation is not supported by this browser.');
      }
      // add custom zoom in/out using zoom state
      map.current.on('move', () => {
        setZoom(map.current?.getZoom());
      });

      // add control to select area and remove area on the map
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        clickBuffer: 2,
        userProperties: true,
        controls: {
          polygon: false,
          trash: false,
        },
        styles: [
          // line stroke, when drawing
          {
            'id': 'gl-draw-line',
            'type': 'line',
            // 'filter': ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
            'layout': {
              'line-cap': 'round',
              'line-join': 'round'
            },
            'paint': {
              'line-color': '#FFFFFF',
              'line-width': 4
            }
          },
          // polygon outline stroke
          // when start drawing it is show border and it's active state for drawn area
          {
            'id': 'gl-draw-polygon-stroke-active',
            'type': 'line',
            // 'filter': ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
            'layout': {
              'line-cap': 'round',
              'line-join': 'round'
            },
            'paint': {
              'line-color': '#FFFFFF',
              'line-width': 4
            }
          },
          // polygon fill, when drawing 
          {
            'id': 'gl-draw-polygon-fill',
            'type': 'fill',
            // 'filter': ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
            'paint': {
              'fill-color': '#FFFFFF',
              'fill-outline-color': '#FFFFFF',
              'fill-opacity': 0.4
            }
          },
          // add contour points when drawing
          {
            'id': 'gl-draw-halos-contour-point',
            'type': 'circle',
            'filter': ['all', ['!=', 'meta', 'midpoint'], ['==', 'active', 'true']],
            'paint': {
              'circle-radius': 7,
              'circle-color': '#FFC800',
            }
          },
          {
            'id': 'gl-draw-contour-point',
            'type': 'circle',
            'filter': ['all', ['!=', 'meta', 'midpoint'], ['==', 'active', 'true']],
            'paint': {
              'circle-radius': 5,
              'circle-color': '#FFFFFF',
            }
          },
          {
            'id': 'gl-draw-midpoint-out',
            'type': 'circle',
            'filter': ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
            'paint': {
              'circle-radius': 7,
              'circle-color': '#FFC800',
            },
          },
          {
            'id': 'gl-draw-midpoint-in',
            'type': 'circle',
            'filter': ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
            'paint': {
              'circle-radius': 5,
              'circle-color': '#FFFFFF',
            },
          }
        ]
      });
      map.current.addControl(draw);
      drawControlRef.current = draw;
      // create a source for drawing polygons and clustering
      map.current?.on('load', () => {
        try {
          map.current?.addSource('polygon-source', {
            type: 'geojson',
            data: drawClustering.current,
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50
          });
        } catch (error) {
          // do nothing
        }
        if (savedJSON?.areas?.length) {
          savedJSON.areas.forEach((item: any) => {
            item.geometry.coordinates = [item.geometry.coordinates]
            handleManualAreaDraw(item);
          })
          const geometry = savedJSON.areas[0].geometry;
          const longlat = getLngLat({ ...geometry, coordinates: [...geometry.coordinates] });
          // this animation is considered essential with respect to prefers-reduced-motion
          map.current?.flyTo({
            center: longlat,
            essential: true
          });
        }
        setIsLoading(false);
      });
      // get polygon geo JSON
      map.current.on('draw.create', (e) => {
        switch (activeMenuRef.current) {
          case 'drawArea':
            handleManualAreaDraw(e.features[0]);
            // area details form open right away after draw completed            
            setFormVisible(true);
            // remove draw menu inactive after drawn polygon
            setActiveMenu('');
            break;
          case 'automaticMapping':
            setDoneBtn(true);
            handleAutoAreaDraw(e.features[0]);
            break;
        }

        // remove draw cursor event
        mapContainer.current?.removeEventListener('mousemove', handleMouseMove, true);
        // remove cursor on complete of draw
        document.querySelectorAll('.tooltip').forEach((item: any) => {
          item.style.display = 'none';
        });
      });

      // update area with coordinates start
      map.current.on('draw.update', (e) => {
        const updatedFeature = e.features[0];
        const areaSqrMtr = calculateArea(updatedFeature.geometry.coordinates[0]);
        if (map.current?.getSource(`source-${updatedFeature.id}`)) {
          // remove source and layers first
          map.current?.getStyle().layers.filter((item) => item.id.includes(updatedFeature.id)).forEach((item) => {
            map.current?.removeLayer(item.id);
          });
          map.current?.removeSource(`source-${updatedFeature.id}`);
          // add resources back to the map
          addResources(updatedFeature.id, updatedFeature.geometry.coordinates[0]);
          // update the form data
          setFormData(formDataRef.current.map((item: IArea) => {
            if (item.area_id === updatedFeature.id) {
              return {
                ...item,
                area_sqr_mtr: areaSqrMtr,
                geometry: {
                  ...item.geometry,
                  coordinates: updatedFeature.geometry.coordinates[0]
                }
              };
            } else {
              return item;
            }
          }));

          const existingDrawClustering = { ...drawClustering.current };
          existingDrawClustering.features.forEach((feature: any) => {
            if (feature.id === `feature-${updatedFeature.id}`) {
              feature.geometry.coordinates = getLngLat(updatedFeature.geometry);
            }
          });
          drawClustering.current = existingDrawClustering;

          // get source to set the clustering geo JSON data to source
          const source: GeoJSONSource = map.current?.getSource('polygon-source') as GeoJSONSource;
          source.setData(existingDrawClustering);
        }
      });
      // update area with coordinates end
    }
    // add the clustering popup on move end event call to count and show the clustering	
    map.current?.on('moveend', () => updateMarkers(map));

    // executes on switching the map styles start
    map.current?.on('style.load', () => {
      try {
        map.current?.addSource('polygon-source', {
          type: 'geojson',
          data: drawClustering.current,
          cluster: true,
          clusterMaxZoom: 12,
          clusterRadius: 100
        });
      } catch (error) {
        // do nothing!
      }
      formDataRef.current.forEach((item: IArea) => {
        if (!map.current?.getSource(`source-${item.area_id}`)) {
          addResources(item.area_id, item.geometry?.coordinates);
        }
      });
      setIsLoading(false);
    });
    // executes on switching the map styles end
    // remove cursor tooltip and automatic mapping infobox 
    document.addEventListener('keydown', function (event) {
      if (event.key === 'Escape') {
        setAutoMapInfo(false);
        mapContainer.current?.removeEventListener('mousemove', handleMouseMove, true); // remove draw cursor event
        // remove cursor on complete of draw
        document.querySelectorAll('.tooltip').forEach((item: any) => {
          item.style.display = 'none';
        });
      }
    });
    // clean up on component unmount
    return () => {
      map.current?.remove();
      // clean up on component unmount to remove the event listener
      mapContainer.current?.removeEventListener('mousemove', handleMouseMove, true);
    }
  }, [mapContainer]);

  // for reverse geocoding the search bar to find location using coordinates
  const coordinatesGeocoder: any = (query: string) => {
    // match anything which looks like decimal degrees coordinate pair
    const matches = query.match(
      /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
    );
    if (!matches) {
      return null;
    }

    function coordinateFeature(lng: string | number, lat: string | number) {
      return {
        center: [lng, lat],
        geometry: {
          type: 'Point',
          coordinates: [lng, lat]
        },
        place_name: 'Lat: ' + lat + ' Lng: ' + lng,
        place_type: ['coordinate'],
        properties: {},
        type: 'Feature'
      };
    }

    const coord1 = Number(matches[1]);
    const coord2 = Number(matches[2]);
    const geocodes = [];
    if (coord1 < -90 || coord1 > 90) {
      // must be lng, lat
      geocodes.push(coordinateFeature(coord1, coord2));
    }
    if (coord2 < -90 || coord2 > 90) {
      // must be lat, lng
      geocodes.push(coordinateFeature(coord2, coord1));
    }
    if (geocodes.length === 0) {
      // else could be either lng, lat or lat, lng
      geocodes.push(coordinateFeature(coord1, coord2));
      geocodes.push(coordinateFeature(coord2, coord1));
    }
    return geocodes;
  };

  useEffect(() => {
    if (showSearch) {
      // add the control to search location on the map
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        localGeocoder: coordinatesGeocoder,
        mapboxgl: mapboxgl,
        marker: false,
        placeholder: 'Search for an address or location',
        reverseGeocode: true,
      });
      map.current?.addControl(geocoder, 'top-right');
      // Add focus to the geocoder
      document.querySelectorAll('.mapboxgl-ctrl-geocoder--input').forEach((item: any) => {
        item.focus();
      });
      setGeocoderControl(geocoder); // used for store and use parent container for remove control
      // show error message if worng coordinates input
      geocoder.on('error', () => {
        toast.error('Wrong latitude/longitude entered', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        geocoder.setInput(''); // clear the input field of search geocoding
      });
      geocoder.on('result', (e) => {
        const { center } = e.result;
        const latitude = center[1];
        const longitude = center[0];
        // set center for map when search
        setCentered({ longitude: longitude, latitude: latitude });
        // hide the input box and clear search
        document.querySelectorAll('.mapboxgl-ctrl-geocoder').forEach((item: any) => {
          item.style.display = 'none';
        });
        if (markerpin.current) {
          markerpin.current.remove();
        }
        if (map.current) {
          markerpin.current = new mapboxgl.Marker({ color: '#FFC800' })
            .setLngLat([longitude, latitude])
            .addTo(map.current);
        }
        if (geocoderControl) {
          setGeocoderControl(undefined); // reset the geocoder control state
        }
        setShowSearch(false);
        setActiveMenu('');
      });
    }
  }, [showSearch]);

  // update the form detail with popup and updated draw area
  useEffect(() => {
    const currentPopup = popups.current.find((item) => item.areaId === confirmModal.polygonId);
    const currentFormData = formDataRef.current.find((item) => item.area_id === confirmModal.polygonId);
    if (currentPopup && currentFormData) {
      const longlat = getLngLat({ ...currentFormData?.geometry, coordinates: [currentFormData?.geometry?.coordinates] });
      const popup = updatePopup(currentPopup, currentFormData, longlat);
      popup?.getElement()?.querySelector('.area-popup-main-div')?.addEventListener('mouseenter', () => {
        onMouseEnterHandler(confirmModal.polygonId);
      })
      popup?.getElement()?.querySelector('.area-popup-main-div')?.addEventListener('mouseleave', () => {
        onMouseLeaveHandler(confirmModal.polygonId);
      })
      handlePopupUpdate(popup, currentPopup.areaId, currentPopup?.id);
    }
  }, [formData]);

  const handlePopupUpdate = (currentPopup: mapboxgl.Popup | undefined, area_id: string, j: number) => {
    const popupEvent = currentPopup?.getElement()?.querySelector(`.area-popup-div-${j}`) as HTMLElement;
    if (popupEvent) {
      const temp = Number(popupEvent?.id.split('_').pop());
      setAreaIndex(temp);
      // open area details form after click on popup
      popupEvent?.addEventListener('click', () => {
        setConfirmModal({ showModal: false, areaId: null, polygonId: area_id });
        setFormVisible(true);
        setAreaIndex(temp);
        // draw.changeMode('direct_select', { featureId: area_id });
      });
    }
    // add onclick event for trash icon to show confirmation modal
    const trashIcon = currentPopup?.getElement()?.querySelector(`#trash_icon_${j}`) as HTMLElement;
    if (trashIcon) {
      const areaId: number = parseInt(trashIcon.id.split('_').pop() || '0');
      trashIcon?.addEventListener('click', function () {
        openConfirmationModal(areaId, area_id)
      });
    }
    // on click polygon event start
    map.current?.on('click', `gl-draw-polygon-fill-${area_id}`, function () {
      setConfirmModal({ showModal: false, areaId: null, polygonId: area_id });
      // area details form open right away after draw completed
      setFormVisible(true);
      setAreaIndex(j);
    });
    // on click polygon event end
  }

  const handleSave = async () => {
    // remove the search box control if it is added
    if (showSearch && geocoderControl) {
      map.current?.removeControl(geocoderControl);
      setShowSearch(false);
    }
    if (formData.length > 0) {
      props.onSaved({ areas: formData });
      // download JSON data
      if (downloadable) {
        const file = new Blob([JSON.stringify({ areas: formData })], { type: 'application/json;charset=utf-8' });
        saveAs(file, 'areas.json');
      }
    } else {
      toast.error('Please draw on map before save', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  }

  const handleSearchBox = () => {
    // add/remove the search location on the map using showSearch state
    if (!showSearch) {
      setShowSearch(true);
      setActiveMenu('search');
      // toggle mode of simple or draw on click of same button 
      const draw = drawControlRef.current;
      const modes: DrawModes | undefined = draw?.modes;
      if (modes) {
        draw?.changeMode(modes.SIMPLE_SELECT);
        mapContainer.current?.removeEventListener('mousemove', handleMouseMove, true); // remove draw cursor event
        // remove cursor on complete of draw
        document.querySelectorAll('.tooltip').forEach((item: any) => {
          item.style.display = 'none';
        });
      }
    } else {
      if (geocoderControl) {
        map.current?.removeControl(geocoderControl);
        setGeocoderControl(undefined); // reset the geocoder control state
      }
      setShowSearch(false);
      setActiveMenu('');
    }
  }

  const handleAutoMapping = () => {
    removeExtraDrawnArea();
    // remove the search box control if it is added
    if (showSearch && geocoderControl) {
      map.current?.removeControl(geocoderControl);
      setShowSearch(false);
    }
    // toggle mode of simple or draw on click of same button 
    const draw = drawControlRef.current;
    const modes: DrawModes | undefined = draw?.modes;
    if (modes) {
      if (draw?.getMode() === modes?.DRAW_POLYGON) {
        if (activeMenu === 'drawArea') {
          setActiveMenu('automaticMapping');
          setDoneBtn(false);
          setAutoMapInfo(true);
          draw?.changeMode(modes.DRAW_POLYGON);
          mapContainer.current?.addEventListener('mousemove', handleMouseMove, true);
        } else {
          setActiveMenu('');
          !areaRef.current &&
            setAutoMapInfo(false);
          draw?.changeMode(modes.SIMPLE_SELECT);
          mapContainer.current?.removeEventListener('mousemove', handleMouseMove, true); // remove draw cursor event
          // remove cursor on complete of draw
          document.querySelectorAll('.tooltip').forEach((item: any) => {
            item.style.display = 'none';
          });
        }
      } else {
        setActiveMenu('automaticMapping');
        setAutoMapInfo(true);
        draw?.changeMode(modes.DRAW_POLYGON);
        mapContainer.current?.addEventListener('mousemove', handleMouseMove, true);
      }
    }
  }

  const handleDrawArea = () => {
    removeExtraDrawnArea();
    // remove the search box control if it is added
    if (showSearch && geocoderControl) {
      map.current?.removeControl(geocoderControl);
      setShowSearch(false);
    }
    // toggle mode of simple or draw on click of same button 
    const draw = drawControlRef.current;
    const modes: DrawModes | undefined = draw?.modes;
    setAutoMapInfo(false);

    if (modes) {
      if (draw?.getMode() === modes?.DRAW_POLYGON) {
        if (activeMenu === 'automaticMapping') {
          setActiveMenu('drawArea');
          !areaRef.current && setAutoMapInfo(false);
          draw?.changeMode(modes.DRAW_POLYGON);
          mapContainer.current?.addEventListener('mousemove', handleMouseMove, true);
        } else {
          setActiveMenu('');
          draw?.changeMode(modes.SIMPLE_SELECT);
          mapContainer.current?.removeEventListener('mousemove', handleMouseMove, true); // remove draw cursor event
          // remove cursor on complete of draw
          document.querySelectorAll('.tooltip').forEach((item: any) => {
            item.style.display = 'none';
          });
        }
      } else {
        setActiveMenu('drawArea');
        draw?.changeMode(modes.DRAW_POLYGON);
        mapContainer.current?.addEventListener('mousemove', handleMouseMove, true);
      }
    }
  }

  const handleChangeView = () => {
    setIsLoading(true);
    // remove the search box control if it is added
    if (showSearch && geocoderControl) {
      map.current?.removeControl(geocoderControl);
      setShowSearch(false);
    }
    // remove the auto mapping or draw control if it is added 
    const draw = drawControlRef.current;
    const modes: DrawModes | undefined = draw?.modes;
    if (modes) {
      if (draw?.getMode() === modes?.DRAW_POLYGON) {
        draw?.changeMode(modes.SIMPLE_SELECT);
        mapContainer.current?.removeEventListener('mousemove', handleMouseMove, true); // remove draw cursor event
        // remove cursor on complete of draw
        document.querySelectorAll('.tooltip').forEach((item: any) => {
          item.style.display = 'none';
        });
        setAutoMapInfo(false);
      }
    }
    // do the rest of the change view functionality
    setActiveMenu('changeView');
    let view;
    if (map.current?.getStyle().sprite?.includes('satellite-streets-v12')) {
      view = 'streets-v12';
    } else {
      view = 'satellite-streets-v12';
    }
    map.current?.setStyle('mapbox://styles/mapbox/' + view);
    getTotalValue();
  }

  const handleZoomIn = () => {
    map.current?.zoomIn();
  }
  const handleZoomOut = () => {
    map.current?.zoomOut();
  }

  const handleAreaDelete = () => {
    // we need to remove the formdata, layer, source & popup on confirm delete area
    if (drawControlRef !== null && confirmModal.areaId !== null && confirmModal.polygonId) {
      // remove formdata single object by specific area id
      setFormData((current) =>
        current.filter((area) => area.id !== confirmModal.areaId)
      );
      drawControlRef.current?.delete(confirmModal.polygonId); // remove drawn polygon by specific polygon id
      // remove layer by specific polygon id
      map?.current?.removeLayer(`gl-draw-polygon-fill-${confirmModal.polygonId}`);
      map?.current?.removeLayer(`gl-draw-polygon-stroke-active-${confirmModal.polygonId}`);
      map?.current?.removeLayer(`gl-draw-polygon-fill-inactive-${confirmModal.polygonId}`);
      map?.current?.removeLayer(`gl-draw-polygon-stroke-inactive-${confirmModal.polygonId}`);
      // remove source by specific polygon id
      map?.current?.removeSource(`source-${confirmModal.polygonId}`);

      // update clustering count after removed any area popup
      const tempDrawClustering = { ...drawClustering.current };
      const keptData = tempDrawClustering.features.filter((d1: { id: string; }) => d1.id !== `feature-${confirmModal.polygonId}`)
      tempDrawClustering.features = [...keptData];
      drawClustering.current = tempDrawClustering;

      // set new updated feature data to source
      const source: mapboxgl.GeoJSONSource = map.current?.getSource('polygon-source') as mapboxgl.GeoJSONSource;
      source.setData(tempDrawClustering);

      // find the corresponding popup reference
      const popupData = popups.current.find(p => p.id === confirmModal.areaId);
      if (popupData) {
        // remove the popup from the map
        popupData.popup.remove();
        // update the state by filtering out the deleted popup
        setPopups(popups.current.filter(p => p.id !== confirmModal.areaId));
      }
      setConfirmModal({ showModal: false, areaId: null, polygonId: '' });
    }
  };

  const handleCancel = () => {
    setConfirmModal({ showModal: false, areaId: null, polygonId: '' });
  };

  const openConfirmationModal = (areaId: number, polygonId: string) => {
    setConfirmModal({ showModal: true, areaId: areaId, polygonId: polygonId });
    setFormVisible(false);
  };

  // to count of area square meter subtotal
  const getTotalValue = () => {
    return formData.reduce((accumulator, object) => {
      return Math.floor(accumulator + object.area_sqr_mtr);
    }, 0);
  };

  const handleCloseBox = () => {
    setSiteInfo(false);
    setFoundAreaInfo(null);
  };

  const onAutoMappingDone = async () => {
    // API call on done button click
    setDoneLoading(true);
    if (areaRef.current) {
      axios.post(api_end_point + '/results', { 'data': [areaRef.current] })
        .then((res: any) => {
          if (res.data.areas.length) {
            const data: any[] = res?.data.areas;
            data.forEach((item: any, index: number) => {
              handleManualAreaDraw({ ...item, area_id: `${areaRef.current?.id}_${index}` });
              drawControlRef.current?.add({
                type: 'Feature',
                properties: {},
                geometry: item.geometry,
                id: `${areaRef.current?.id}_${index}`,
              });
            });
            setFoundAreaInfo(data.length);
          } else {
            toast.error('No area found', {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          }

          // delete drawn area for auto mapping
          areaRef.current?.id &&
            drawControlRef.current?.delete(areaRef.current.id.toString());

          // remove draw menu inactive after drawn polygon
          setActiveMenu('');
          setAutoMapInfo(false);
          setDoneLoading(false);
        })
        .catch((error) => {
          // handle API call failed errors here
          toast.error(error.message, {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });

          // delete drawn area for auto mapping
          areaRef.current?.id &&
            drawControlRef.current?.delete(areaRef.current.id.toString());

          // remove draw menu inactive after drawn polygon
          setActiveMenu('');
          setAutoMapInfo(false);
          setDoneLoading(false);
        });
    } else {
      toast.error('Please draw on map before save', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  }

  return (
    <>
      <div className='h-full'>
        {isLoading ?
          <Loader showOverlapper={true} /> :
          <></>
        }
        <ToastContainer />
        {/* arrow tooltip */}
        <ArrowTooltip title={activeMenu === 'drawArea' ? 'Draw area' : 'Outline site'} />
        {/* main map div initialize */}
        <div ref={mapContainer} className='w-full h-full inset-0 absolute' />
        {/* introduction site pop up */}
        {siteInfo &&
          <Infobox className='w-[272px] gap-1' title='Site' isOpen={true} isClosable={true} handleCloseBox={handleCloseBox}>
            <Text className='tracking-[0.4px] leading-5 text-sm font-roboto not-italic font-normal text-[#3D3D3C] mt-[4px]'>
              Add your customer’s site by using the toolbox to the right. You can either create a site by automatic mapping or draw the areas manually.
            </Text>
          </Infobox>
        }
        {autoMapInfo &&
          <Infobox className='w-[272px] gap-1' title='Automatic mapping' isOpen={true} isClosable={false} restrictClose={true}>
            <div>
              <Text className='tracking-[0.4px] leading-5 text-sm font-roboto not-italic font-normal text-[#3D3D3C] mt-[4px]'>
                Generate areas automatically by outlining the customer’s site.
              </Text>
              <div className={`flex items-center gap-2.5 max-w-[100px] w-full  overflow-hidden rounded-2xl bg-primary mt-4 ${!doneBtn || doneLoading ? 'opacity-30' : ''}`}>
                <button
                  className='relative flex flex-row justify-center items-center pt-0 px-5 pb-[1px] gap-[10px] w-[147px] h-[48px] font-husqvarna not-italic font-bold text-sm leading-[14px] text-center tracking-[1px] uppercase text-white'
                  onClick={onAutoMappingDone}
                  disabled={!doneBtn}
                >
                  {doneLoading ? <Loader size={30} showOverlapper={false} /> : 'Done'}
                </button>
              </div>
            </div>
          </Infobox>
        }
        {(foundAreaInfo && foundAreaInfo >= 0) &&
          <Infobox className='w-[272px] gap-1' title='Found areas' isOpen={true} isClosable={true} handleCloseBox={handleCloseBox}>
            <div>
              <Text className='tracking-[0.4px] leading-5 text-sm font-roboto not-italic font-normal text-[#3D3D3C] mt-[4px]'>
                We identified {foundAreaInfo} areas within the site. To edit an area or add details, simply click on it.
              </Text>
              <div className='flex item-start gap-2 self-stretch mt-4'>
                <InfoIcon color='#3D3D3C' className='flex-shrink-0 cursor-pointer' />
                <Text className='tracking-[0.4px] leading-4 text-xs font-roboto not-italic font-normal text-[#686867]'>
                  You can always edit, delete or add a new area later on.
                </Text>
              </div>
            </div>
          </Infobox>
        }
        {/* confirmation modal for delete area polyogon */}
        {confirmModal.showModal && (
          <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 z-[9999]'>
            <ConfirmationModal
              title={`You are about to delete the area “${formData.find(area => area.id === confirmModal.areaId)?.name}”`}
              message='Are you sure that you want to delete the area permanently?'
              confirmBtnLbl='Delete Area'
              onConfirm={handleAreaDelete}
              cancelBtnLbl='Cancel'
              onCancel={handleCancel}
            />
          </div>
        )}
        {/* header */}
        {activeMenu !== 'save' ? <Header project={project_name} count={formData.length} subtotal={getTotalValue()} /> : ''}
        {/* area form */}
        {formVisible &&
          <AreaForm formData={formData} setFormData={setFormData} setFormVisible={setFormVisible} areaIndex={areaIndex} />
        }
        {/* sidebar */}
        {activeMenu !== 'save' ? <Sidebar activeMenu={activeMenu} handleSave={handleSave} handleSearchBox={handleSearchBox} handleAutoMapping={handleAutoMapping} handleDrawArea={handleDrawArea} handleChangeView={handleChangeView} handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} /> : ''}
      </div>
    </>
  );
};

export default Husqvarnamap;