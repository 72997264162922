import React from 'react'
import Husqvarnamap from './pages/main'
import areaData from './areaData.json'

function App() {
  const MAPBOX_TOKEN: string = process.env.REACT_APP_MAPBOX_TOKEN || '';
  const API_ENDPOINT: string = process.env.REACT_APP_API_ENDPOINT ?? '';
  const jsonData = areaData ? areaData : { 'areas': [] };
  const onSaveResponse = (childData: any) => {
    console.log('Response =>', childData);
  }
  return (
    <div style={{ height: '100vh' }}>
      <Husqvarnamap
        access_token={MAPBOX_TOKEN}
        project_name='TCSP'
        api_end_point={API_ENDPOINT}
        savedJSON={jsonData}
        onSaved={onSaveResponse}
        downloadable={false}
      />
    </div>
  );
}

export default App
