import React from 'react';
import { ITooltip } from '../app/interfaces';
import { clsxm } from '../app/utils';
import { Text } from '../component';

export function Tooltip({ image, message, description, direction, className, children, childClass}: ITooltip) {
    return (
        <div className="flex group relative">
            {children}
            <div className={clsxm(
                'flex items-start absolute transition-all delay-500 duration-300 select-none opacity-0 group-hover:opacity-100 pointer-events-none',
                'bg-white',
                direction === 'top' && ['top-16'],
                direction === 'right' && ['right-16'],
                direction === 'bottom' && ['bottom-16'],
                direction === 'left' && ['left-16'],
                className
            )}
            >
                {image ?
                    <>
                        <img src={image} alt={message} className='w-[296px] h-[166px] rounded-2xl' />
                        <div className={clsxm('w-[296px] px-4 py-0 gap-1', childClass)}>
                            <Text className={clsxm('w-[272px] h-[28px] font-husqvarna font-bold text-xl leading-7')}>
                                {message}
                            </Text>
                            <Text className={clsxm('w-[272px] font-roboto font-normal text-sm leading-5 tracking-[0.4px] text-left text-secondary')}>
                                {description}
                            </Text>
                        </div>
                    </>
                    :
                    message
                }
            </div>
        </div>
    );
}