import mapboxgl from 'mapbox-gl';
import { MutableRefObject } from 'react';

const markers: { [key: string]: mapboxgl.Popup } = {};

const getHTML = (count: number) => {
  return `<div>
  <div class='area-popup-div area-popup-div-common' id='area_div'>
  <div id='area_popup_div'>
  <div class='area-popup-div-block'>
  <p class='area-popup-main-title'>${count > 1 ? (count + ' Areas') : '1 Area'}</p>
  </div>
  </div>
  </div>
  </div>`
}

const updateMarkers = (map: MutableRefObject<mapboxgl.Map | null>) => {
  // remove previous popups from DOM
  document.querySelectorAll('.cluster-area-popup').forEach((item: any) => {
    item.remove();
  });

  const features = map.current?.querySourceFeatures('polygon-source') as any;
  // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
  // and add it to the map if it's not there already
  for (const feature of features) {
    const coords = feature.geometry.coordinates;
    const props: any = { ...feature.properties };

    /* eslint-disable */
    const id = props?.cluster_id;
    let marker: any = markers[id];

    // add popup for clustering count here
    marker = markers[id] = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: 'bottom-left',
      className: 'cluster-area-popup'
    })
      .setLngLat(coords)
      .setHTML(getHTML(props?.point_count));

    // add cluster popup to map
    marker.addTo(map.current);
  }

  if (features.some((item: any) => Object.entries(item.properties).length > 0)) {
    // display none to area popups when cluster count arrive
    document.querySelectorAll('.area-popup-head').forEach((item: any) => {
      item.style.setProperty('display', 'none', 'important');
    });
  } else {
    document.querySelectorAll('.area-popup-head').forEach((item: any) => {
      item.style.setProperty('display', 'flex', 'important');
    });
    document.querySelectorAll('.cluster-area-popup').forEach((item: any) => {
      item.style.setProperty('display', 'none', 'important');
    });
  }
}
export default updateMarkers;