import React from 'react';
import { ISidebar } from '../../app/interfaces';
import { Card } from '../card';
import { LayerIcon, MagicPenIcon, MinusIcon, PenIcon, PlusIcon, SaveIcon, SearchIcon } from '../svg';
import { Tooltip } from '../tooltip';
import { Divider } from '../divider';
import drawAreaImg from '../../assets/images/drawarea.png';
import autoMapImg from '../../assets/images/automap.png';

const Sidebar = ({
    activeMenu,
    handleSave,
    handleSearchBox,
    handleAutoMapping,
    handleDrawArea,
    handleChangeView,
    handleZoomIn,
    handleZoomOut
}: ISidebar) => {
    return (
        <Card className="flex flex-col items-center absolute w-[64px] h-[520px] top-[52px] right-3 rounded-[20px] p-2 gap-40 bg-white">
            <div className='absolute group rounded-2xl w-[48px] h-[48px] cursor-pointer bg-primary duration-[320ms]'>
                <Tooltip message={'Save'} direction={'right'} className='flex-row w-[55px] h-[44px] p-3 rounded-2xl font-roboto not-italic font-normal text-sm leading-5 tracking-[0.4px] text-secondary gap-[10px] justify-center delay-[550ms]'>
                    <SaveIcon className='absolute m-[14.36px]' onClick={handleSave} color='#FFFFFF' />
                </Tooltip>
            </div>
            <div className={`flex flex-row justify-center items-center absolute w-[48px] h-[48px] rounded-xl top-[68px] cursor-pointer duration-[320ms] ${activeMenu === 'search' ? 'bg-hover' : 'bg-white'} hover:bg-hover`}>
                <Tooltip message={'Search'} direction={'right'} className='flex-row w-[69px] h-[44px] p-3 rounded-2xl font-roboto not-italic font-normal text-sm leading-5 tracking-[0.4px] text-secondary gap-[10px] justify-center delay-[550ms]'>
                    <SearchIcon onClick={handleSearchBox} color='#3D3D3C' />
                </Tooltip>
            </div>
            <Divider className='absolute top-[136px] l-[5px]' />
            <div className={`flex flex-row justify-center items-center absolute w-[48px] h-[48px] rounded-xl top-[156px] cursor-pointer duration-[320ms] ${activeMenu === 'automaticMapping' ? 'bg-hover' : 'bg-white'} hover:bg-hover`}>
                <Tooltip image={autoMapImg} message={'Automatic mapping'} description={'Generate areas automatically by outlining the customer’s site.'} direction={'right'} className='flex-col w-[320px] h-[302px] px-3 pt-3 pb-6 rounded-3xl gap-[20px] justify-center delay-[550ms]' childClass='h-[80px]'>
                    <MagicPenIcon onClick={handleAutoMapping} color='#3D3D3C' />
                </Tooltip>
            </div>
            <div className={`flex flex-row justify-center items-center absolute w-[48px] h-[48px] rounded-xl top-[224px] cursor-pointer duration-[320ms] ${activeMenu === 'drawArea' ? 'bg-hover' : 'bg-white'} hover:bg-hover`}>
                <Tooltip image={drawAreaImg} message={'Create and edit area'} description={'Create an area manually by clicking out the contours of it. Connect the last contour points to finish the area. Drag and move the contour points to edit the area.'} direction={'right'} className='flex-col w-[320px] h-[354px] px-3 pt-3 pb-6 rounded-3xl gap-[20px] justify-center delay-[550ms]' childClass='h-[132px]'>
                    <PenIcon onClick={handleDrawArea} color='#3D3D3C' />
                </Tooltip>
            </div>
            <Divider className='absolute top-[292px] l-[5px]' />
            <div className='flex flex-row justify-center items-center absolute w-[48px] h-[48px] rounded-xl top-[312px] cursor-pointer duration-[320ms] bg-white hover:bg-hover'>
                <Tooltip message={'Switch layer'} direction={'right'} className='flex-row w-[110px] h-[44px] p-3 rounded-2xl font-roboto not-italic font-normal text-sm leading-5 tracking-[0.4px] text-secondary gap-[10px] justify-center delay-[550ms]'>
                    <LayerIcon onClick={handleChangeView} color='#3D3D3C' />
                </Tooltip>
            </div>
            <Divider className='absolute top-[380px] l-[5px]' />
            <div className='flex flex-col items-start absolute p-[0px] w-[48px] h-[96px] top-[400px]'>
                <div className={`flex flex-row justify-center items-center p-[12px] gap-[10px] w-[48px] h-[48px] rounded-xl cursor-pointer duration-[320ms] ${activeMenu === 'zoomIn' ? 'bg-hover' : 'bg-white'} hover:bg-hover`}>
                    <Tooltip message={'Zoom in'} direction={'right'} className='flex-row w-[80px] h-[44px] p-3 rounded-2xl font-roboto not-italic font-normal text-sm leading-5 tracking-[0.4px] text-secondary gap-[10px] justify-center delay-[550ms]'>
                        <PlusIcon onClick={handleZoomIn} color='#3D3D3C' />
                    </Tooltip>
                </div>
                <div className={`flex flex-row justify-center items-center absolute p-[12px] gap-[10px] w-[48px] h-[48px] rounded-xl top-[48px] cursor-pointer duration-[320ms] ${activeMenu === 'zoomOut' ? 'bg-hover' : 'bg-white'} hover:bg-hover`}>
                    <Tooltip message={'Zoom out'} direction={'right'} className='flex-row w-[91px] h-[44px] p-3 rounded-2xl font-roboto not-italic font-normal text-sm leading-5 tracking-[0.4px] text-secondary gap-[10px] justify-center delay-[550ms]'>
                        <MinusIcon onClick={handleZoomOut} color='#3D3D3C' />
                    </Tooltip>
                </div>
            </div>
        </Card>
    );
}
export default Sidebar;