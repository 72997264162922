import React from 'react';
import { IArrowTooltip } from '../app/interfaces';

export function ArrowTooltip({ title }: IArrowTooltip) {
    return (
        <div className='tooltip absolute hidden h-[44px] rounded-2xl pt-[9px] pb-3 px-3  gap-[10px] bg-white z-[9999999]'>
          <span className='font-roboto font-normal text-sm leading-5 tracking-[0.4px] text-[#3D3D3C]'>
            { title }
          </span>
        </div>
    );
}