import React from 'react';
import { IConfirmDelete } from '../app/interfaces';
import { Card, Text } from '../component';

export function ConfirmationModal({ title, message, confirmBtnLbl, onConfirm, cancelBtnLbl, onCancel }: IConfirmDelete) {
  return (
    <Card className="flex flex-col items-start absolute p-10 gap-8 rounded-3xl w-[600px] h-[232px] left-[calc(50%-600px/2)] top-[calc(50%-232px/2)] bg-white">
      <div className='flex flex-col items-start p-0 gap-4 w-[520px] h-[72px]'>
        <Text className='font-husqvarna not-italic font-bold text-[28px] leading-8 tracking-[-0.2px] text-secondary'>
          {title}
        </Text>
        <Text className='self-stretch font-roboto not-italic font-normal text-base leading-[0.4px] text-secondary pt-4'>
          {message}
        </Text>
      </div>
      <div className='flex flex-row justify-end items-start p-0 gap-4 w-[520px] h-[48px]'>
        <div className='flex flex-row items-center p-0 gap-[10px] w-[113px] border border-solid border-secondary rounded-2xl'>
          <button onClick={onCancel} className='flex flex-row justify-center items-center pt-0 px-8 pb-[1px] gap-[10px] w-[113px] h-[48px] font-husqvarna not-italic font-bold text-sm leading-[14px] text-center tracking-[1px] uppercase text-secondary'>{cancelBtnLbl}</button>
        </div>
        <div className='flex flex-row items-center p-0 gap-[10px] w-[147px] rounded-2xl bg-primary'>
          <button onClick={onConfirm} className='flex flex-row justify-center items-center pt-0 px-5 pb-[1px] gap-[10px] w-[147px] h-[48px] font-husqvarna not-italic font-bold text-sm leading-[14px] text-center tracking-[1px] uppercase text-white'>{confirmBtnLbl}</button>
        </div>
      </div>
    </Card>
  );
};