import React from 'react';
import { IHeader } from '../../app/interfaces';
import { Text } from '../typography';
import { VerticalDivider } from '../vertical-divider';

const Header = ({ project, count, subtotal }: IHeader) => {
    return (
        <div className='absolute h-[40px] top-0 left-0 right-0 bg-[#000000] opacity-70'>
            <div className='flex items-center absolute h-[28px] top-[6px] left-[24px] font-roboto not-italic font-normal text-lg tracking-[0.4px] leading-7 text-white'>
                <Text>{project}</Text>
            </div>
            <div className='flex items-center absolute p-0 gap-[28px] h-[24px] top-[6px] right-[24px]'>
                <Text className='flex items-center h-[28px] top-[6px] left-[24px] font-roboto not-italic font-normal text-lg tracking-[0.4px] leading-7 text-white'>
                    {count} areas
                </Text>
                <VerticalDivider className='w-5 h-0 border-[0.5px] border-solid border-white rotate-90' />
                <Text className='flex items-center h-[28px] top-[6px] left-[24px] font-roboto not-italic font-normal text-lg tracking-[0.4px] leading-7 text-white'>
                    {subtotal} m<sup>2</sup>
                </Text>
            </div>
        </div>
    );
};
export default Header;