import React, { useEffect, useState } from 'react';
import { IInfobox } from '../../app/interfaces';
import { clsxm } from '../../app/utils';
import { CloseIcon } from '../svg';
import { Text } from '../typography';

const Infobox = ({ className, title, isOpen, isClosable, handleCloseBox, children, restrictClose }: IInfobox) => {

    const [closePopup, setClosePopup] = useState(false);

    // add an event listener to detect clicks outside the popup
    useEffect(() => {
        if (!restrictClose) {
            const handleOutsideClick: any = (event: MouseEvent) => {
                const targetElement = event.target as Element;
                if (isOpen && targetElement && !targetElement.closest('.infobox-content')) {
                    setClosePopup(true);
                    setTimeout(() => {
                        handleCloseBox && handleCloseBox()
                    }, 1000);
                }
            };
            document.addEventListener('mousedown', handleOutsideClick);
            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
        }
    }, [isOpen]);

    return (
        isOpen ?
            <div className={`absolute w-[320px] top-[52px] left-[12px] rounded-3xl p-6 bg-hover shadow-md infobox-content ${closePopup ? 'opacity-0' : 'opacity-100'}`}>
                <div className={clsxm(className)}>
                    {isClosable &&
                        <div className='absolute right-2 top-2 cursor-pointer p-2 rounded-lg bg-black bg-opacity-0 hover:bg-opacity-5 transition-all duration-300 active:bg-opacity-10'
                            onClick={() => {
                                setClosePopup(true);
                                setTimeout(() => {
                                    handleCloseBox && handleCloseBox()
                                }, 1000);
                            }}>
                            <CloseIcon color='#3D3D3C' />
                        </div>
                    }
                    <Text className={clsxm('text-xl font-husqvarna not-italic font-bold leading-6 text-secondary')}>
                        {title}
                    </Text>
                    {children}
                </div>
            </div>
            :
            <></>
    );
};
export default Infobox;
